import { Link } from 'components/common/Link';
import { Button } from 'components/common/buttons/Button';
import { Dialog, DialogProps } from 'components/layouts/Dialog/Dialog';
import { Heading3, Paragraph } from 'components/typography';
import { format } from 'date-fns';

import { Suspension } from 'generated/graphql';
import { useDialog } from 'hooks/useDialog';
import { useTheme } from 'styled-components';

type SuspensionModalProps = {
  suspension: Suspension;
} & DialogProps;

export const WarningDialog = ({ ...props }: SuspensionModalProps) => {
  const dialog = useDialog();
  const close = () => {
    props?.onClose?.();
    dialog.close(props.id);
  };

  return (
    <Dialog {...props} stayOpenOnOutsideClick>
      <div className="max-w-[600px] p-8">
        <Heading3 className="mb-4">You have been warned</Heading3>
        <Paragraph size="medium">
          We have found your behavior a violention against our{' '}
          <Link target="_blank" href="https://hiber3d.com/terms-of-use">
            Terms of Use
          </Link>
        </Paragraph>
        <div className="flex justify-center mt-8">
          <Button variety="primary" size="medium" text="I understand" onClick={close} />
        </div>
      </div>
    </Dialog>
  );
};

export const SuspensionDialog = ({ suspension, ...props }: SuspensionModalProps) => {
  const dialog = useDialog();
  const theme = useTheme();
  const close = () => {
    props?.onClose?.();
    dialog.close(props.id);
  };

  return (
    <Dialog {...props} stayOpenOnOutsideClick>
      <div className="max-w-[600px] p-8">
        <Heading3 className="mb-4">You have been suspended</Heading3>
        <Paragraph size="medium">
          We have found your behavior a violention against our{' '}
          <Link target="_blank" href="https://hiber3d.com/terms-of-use">
            Terms of Use
          </Link>
          , and you are suspended until {format(new Date(suspension.endedAt), 'MMM d, yyyy, k:mm')}
        </Paragraph>
        <Paragraph size="medium" className="mt-4">
          During your suspension you can still play games, but you&apos;re not allowed to:
        </Paragraph>

        <ul style={{ color: theme.colors.text.default }}>
          <li>
            <Paragraph size="medium">Comment on games or reply to comments</Paragraph>
          </li>
          <li>
            <Paragraph size="medium">Create new Worlds</Paragraph>
          </li>
          <li>
            <Paragraph size="medium">Publish or Edit Worlds</Paragraph>
          </li>
          <li>
            <Paragraph size="medium">Update your profile picture</Paragraph>
          </li>
          <li>
            <Paragraph size="medium">Send or recieve messages in the chat</Paragraph>
          </li>
        </ul>

        <Paragraph size="medium">{suspension.customMessage}</Paragraph>
        <div className="flex justify-center mt-8">
          <Button variety="primary" size="medium" text="I understand" onClick={close} />
        </div>
      </div>
    </Dialog>
  );
};
