import { Suspension as SuspensionEntity, SuspensionType, useSetSeenSuspensionMutation } from 'generated/graphql';
import { useDialog } from 'hooks/useDialog';
import { useEffectOnce } from 'lib/useOnMount';
import React from 'react';
import { SuspensionDialog, WarningDialog } from './SuspensionDialog';

interface SuspensionProps {
  suspension: SuspensionEntity;
}

export const Suspension: React.FC<SuspensionProps> = ({ suspension }: SuspensionProps) => {
  const [setSeenSuspension] = useSetSeenSuspensionMutation();
  const dialog = useDialog();

  useEffectOnce(() => {
    dialog.open(suspension.type === SuspensionType.Suspended ? 'suspension' : 'warning', {
      name: 'suspended_message',
      origin: 'login',
      description: 'Information about being suspended',
    });
  });

  if (suspension.type === SuspensionType.Warning) {
    return (
      <WarningDialog
        id="warning"
        suspension={suspension}
        onClose={() => {
          setSeenSuspension({
            variables: {
              id: suspension.id,
            },
          });
        }}
      />
    );
  }

  return (
    <SuspensionDialog
      id="suspension"
      suspension={suspension}
      onClose={() => {
        setSeenSuspension({
          variables: {
            id: suspension.id,
          },
        });
      }}
    />
  );
};
